@use 'common'
@use '_fonts'

html
	overflow-y: scroll

html,
body
	color: common.$color-blue
	padding: 0
	margin: 0
	font-weight: 800
	font-family: common.$bodyFont

h1, h2, h3, h4, h5, h6
	font-family: common.$headingFont
	font-weight: 800
	color: common.$color-green
	line-height: 1.1

a
	color: inherit
	text-decoration: none

button
	cursor: pointer

*
	box-sizing: border-box

:root
	--page-horizontal-spacing: 20px
	--page-horizontal-inner-spacing: 80px

	--content-width-normal: 960px

	--border-radius: 10px

	--page-tile-gap: 40px

	@media (min-width: 768px)
		--page-tile-gap: 80px

	@media (min-width: 1000px)
		--page-horizontal-inner-spacing: 100px
