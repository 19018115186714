$color-blue: #0101ff
$color-green: #00FF00

$break480: 480px
$break560: 560px
$break768: 768px
$break1000: 1000px

$ralewayFont: Raleway
$bodyFont: $ralewayFont, sans-serif
$headingFont: $bodyFont
